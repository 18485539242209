<template>
    <div class="Coupon">
          <div class="item-header">
            
            <div class="screen-list-item ">
                <span class="screen-item-label">手机号</span>
                <a-input placeholder="手机号" style="width: 200px;border-top-right-radius: 0;border-bottom-right-radius: 0;" v-model="serachParams.phone"/>
                <a-button type="primary" @click="_getData" style="border-top-left-radius: 0;border-bottom-left-radius: 0;height: 36px;" >搜索</a-button>
            </div>

            <!-- <div class="screen-list-item">
                <span class="screen-item-label">套餐名称</span>
                <a-input placeholder="套餐名称" style="width: 200px;" v-model="serachParams.codePackageName"/>
            </div>

            <div class="screen-list-item">
                <a-button style="margin-right:20px" @click="resetBtn()">重置</a-button>
                <a-button type="primary" @click="serachBtn()">查询</a-button>
            </div> -->

            <div class="screen-list-item">
                <a-button type="primary" @click="onTtableClick" ><a-icon type="plus" />开通</a-button>
            </div>
        </div>

        <div class="coupon-content" :style="{'min-height':minHeight}">
            <div style="margin-top:15px;">
                <a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered>

                    <div slot="content" slot-scope="text, record, index">
                        <a-tag v-for="(item,index) in record.content">{{item}}</a-tag>
                    </div>

                    <div slot="action" slot-scope="text, record, index">
                        <a-popover placement="bottom">
                            <template slot="content" >
                              <div class="edit-item">
                                  <a href="#" @click="_showEdit(record)">编辑</a>
                              </div>
                            </template>
                            <a-icon type="dash" class="smalldash" @click.stop/>
                        </a-popover>
                    </div>
                </a-table> 
            </div>
            <div style="margin: 20px 0;text-align: right;" v-if="total > 0">
                <a-pagination :defaultCurrent="1" :total="total" @change="pageChange" />
            </div>
        </div>

        <a-drawer :title="package_popup_title" placement="right" :closable="false" width="600" @close="_hideCancel" :visible="ExChangeVisible">
            <div class="item-list">
                 <label>手机号:</label>
                 <div class="item-content">
                     <a-input v-model="phone" placeholder="手机号码" style="width: 280px;"/>
                     <a-button type="primary" @click="searchUser">搜索</a-button>
                 </div>
             </div>
             <div v-if="isSearch" class="item-list">
                 <label>用户状态:</label>
                 <div class="item-content">
                     <div v-if="isReg" class="register">用户已注册</div>
                     <div v-else class="unregister">
                         <span class="unregister-text">用户未注册</span>
                         <a-button type="primary" @click="registerUser">创建用户</a-button>
                     </div>
                 </div>
             </div>

             <div v-if="isReg" class="item-list">
                 <label>课程内容:</label>
                 <div class="item-content">

                    <!-- <a-select value="[1]" mode="multiple" :open="false" style="width: 100%" placeholder="选择内容" allowClear @click="openClass"></a-select> -->

                    <a-tree-select 
                        style="width: 100%" placeholder="选择内容" allow-clear multiple show-checked-strategy="SHOW_PARENT" :tree-data="treeData" :open="false" 
                        @click="openClass" :value="package_value" @change="_clearPackageValue">
                   </a-tree-select>

                 </div>
             </div>

             <div v-if="isReg" class="btn-list" style="margin-top: 100px;">
                    <a-button style="margin-right: 20px;" @click="_hideCancel">取消</a-button>
                    <a-button type="primary" @click="do_save">确认开通</a-button>
             </div>

             <a-modal :width='1200'  :bodyStyle="lessonBodyStyle" v-model="lessonState" title="课程类目" @ok="modalHandleOk" @cancel="modelCancel">
                  <div class="lesson-list">
                        <div class="class-item" v-for="(item, index) in classTypeList" @click.stop="switchClass(item)">
                            <a href="javascript:void(0);">
                                <span>{{item.title}}</span>&nbsp;
                                <span>{{item.select_count}}/{{item.all_count}}</span>
                            </a>
                        </div>
                  </div>
                  <div class="line"></div>
                  <div class="lesson-list" style="width: 30%">
                      <!-- <span class="lessonCheckAll">
                          <a-checkbox :checked="lessonCheckAllState" @change="onLessonCheckAll">全部</a-checkbox>
                      </span> -->
                      <div class="class-item" v-for="(item, index) in classList" @click.stop="openClassCate(item,0,index)">
                            <span class="Checkbox">
                                <a-checkbox :checked="item.checked" @change.stop="onLessonSelect(item,index,$event)"></a-checkbox>
                            </span>
                            <a href="javascript:void(0);">
                                <span class="class-title">{{item.title}}</span>&nbsp;
                                <span v-if="classTypeValue == 1">{{item.select_count}}/{{item.all_count}}</span>
                            </a>
                      </div>
                  </div>
                  <div class="line" v-if="categoryList.length > 0"></div>
                  <div class="lesson-list" v-if="categoryList.length > 0">
                      <div class="class-item" v-for="(item, index) in categoryList" @click.stop="openCategoryCate(item,0,index)">
                            <span class="Checkbox">
                                <a-checkbox :checked="item.checked" @change.stop="onLessonSelect_1(item,index,$event)"></a-checkbox>
                            </span>
                            <span class="class-title">{{item.title}}</span>&nbsp;
                            <span>{{item.select_count}}/{{item.all_count}}</span>
                      </div>
                  </div>

                 <div class="line"  v-if="courseLessonList ===null"></div>
                 <div class="lesson-list" v-if="courseLessonList&& showCourseLessonList " style="height: auto;">
                     <a-checkbox  :checked="checkAllLessonByCate" @click.stop="onLessonSelectAll()" >
                         <span v-if="checkAllLessonByCate">已全选，点击反选</span>
                         <span v-if="!checkAllLessonByCate">点击全选</span>
                     </a-checkbox>

                     <div class="class-item" v-if="typeof item !=='string'" v-for="(item,index) in courseLessonList" :key="index">
                            <span class="Checkbox">
                                <a-checkbox :checked="item.checked" @click.stop="onLessonOneCheck(item)"></a-checkbox>
                            </span>
                         <span class="class-title">{{item.title}}</span>&nbsp;
                     </div>
                 </div>

            </a-modal>
        </a-drawer>
        <div v-html="submintContent"></div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name:'OpenCourse',
    components: {},
    data(){
        return{
            columns: [
                { title: '序号', dataIndex: 'number', key: 'number',align:'center',width:50 },
                { title: '手机号', dataIndex: 'phone', key: 'phone',align:'center',width:300 },
                { title: '用户名', dataIndex: 'name', key: 'name',align:'center',width:200 },
                { title: '课程内容', dataIndex: 'content', key: 'content',align:'center',width:300 ,scopedSlots: { customRender: 'content' },},
                { title: '创建信息', dataIndex: 'createinfo', key: 'createinfo', align:'center',width: 200 },
                { title: '操作', dataIndex: 'action', key: 'action',align:'center',width:100 ,scopedSlots: { customRender: 'action' },},
            ],

            infoData:[],
            startDate: '',
            endDate: '',
            code_name:'',
            minHeight:'500px',
            total:0,
            page:1,
            ExChangeVisible:false,

            aiCourseSetting:[],
            lessonState:false,
            lessonTitle:'选择课程',
            lesson_mu:'',
            lesson_checkbox_option:[],
            lesson_select:[],
            checkAll: false,
            category_check:false,
            _tmp_index_1:null,  //核心课索引
            _tmp_index_2:null,   //课程分类索引
            _tmp_index_3:null,   //课时索引
            _tmp_type_value:null,
            aiCourseList:[],

            coreCourseList:[],
            coreCategoryList:[],
            expandCourseList:[],
            expandCourse:[],

            codeCatogories:[],

            categoryId:'',
            category_unique:'',
            categoryTitle:'',


            codePackageName: '',
            sendCount: 0,
            startTime:'',
            expireTime:'',
            sendChannel: '17',
            exchangeType: 0,
            courseTitle:'',
            courseId:'',
            course_unique:'',

            channelList:[],
            activeKey:null,
            aiCourseIndex:null,

            stopVisible:false,

            serachParams:{
                phone: '',
            },

            ExChangeEditVisible:false,
            codePackageId:'',


            is_publish: '1',
            platform_type: '1',
            treeData:[],
            lessonTreeData:[
              {
                title: 'parent 1',
                key: '0-0',
                children: [
                  {
                    title: 'parent 1-0',
                    key: '0-0-0',
                  },
                ],
              },
              {
                title: 'parent 2',
                key: '0-1',
                children: [
                  {
                    title: 'parent 2',
                    key: '0-1-0',
                  },
                ],
              }
            ],
            lessonBodyStyle:{
                height:'auto',
                overflow:"hidden"
            },
            classTypeList: [],
            classList: [],
            categoryList: [],
            courseLessonList: {},
            showCourseLessonList:false,
            LessonCheckState:false,
            lessonCheckAllState:false,
            classTypeValue: null,

            package_value:[],
            package_type: 1,
            package_content:'',
            package_info: null,
            package_popup_title:'新建套餐',
            package_id: '',

            is_edit: false,


            phone: '',
            isReg: false,//是否已经注册
            isSearch: false,
            user_unique: '',
            submintContent:'',

            // 全选/反选 当前分类
            checkAllLessonByCate:false,
        }
    },
    created(){
        this._getData();
    },
    mounted(){
         
    },
    methods:{
     moment,
     serachBtn(){
        this.page = 1;
        this._getData();
     },
     onTtableClick(){
        this.ExChangeVisible = true;
        // this._getSendChannel();
        // this._init_codeCatogories();
     },
     resetBtn(){
        this.serachParams = {
            phone: ''
        }
        this.page = 1;
        this._getData();
     },
     changeTime(value){
        if (value.length > 0) {
            this.serachParams.startTime = moment(value[0]._d).format('YYYY-MM-DD');
            this.serachParams.expireTime = moment(value[1]._d).format('YYYY-MM-DD');
        } else {
            this.serachParams.startTime = '';
            this.serachParams.expireTime = '';
        }
     },
     pageChange(pageNumber){
        this.page = pageNumber;
        this._getData();
     },
     _getData(){
        this.$message.loading('数据加载中...');
        this.serachParams.page = this.page;
        this.$axios.post(8000214, this.serachParams,res => {
            if(res.data.code==1){
                this.infoData = res.data.data
                this.total = res.data.count;
            }else{
                this.infoData = [];
                this.total = 0;
            }
            this.$message.destroy()
        });
     },
     _hideCancel(){
        this.ExChangeVisible = false;
        this.isReg = false;
        this.isSearch = false;
        this.phone = '';
        this.is_edit = false;
     },
     do_save(){
        this.$message.loading('正在执行中...');

        let params = {
            phone: this.phone,
            user_unique: this.user_unique,
            codeCatogories: JSON.stringify(this.codeCatogories),
            content: this.package_content,
        }
        this.$axios.post(8000213, params,res => {
            if (res.data.code == 1) {
                this.$message.success('操作成功');
                this.ExChangeVisible = false;
                this.$message.destroy()
                this._getData();
            } else {
                this.$message.error(res.data.message);
            }
        });

     },
     onChangePublish(value){
        this.is_publish = value;
     },
     onChangePlatformType(value){
        this.platform_type = value;
     },
     _set_package_content(str){
        this.package_content = str;
     },
     modalHandleOk(e){
        this.lessonState = false;
        this._create_package_data();
     },
     _create_package_data(){
        let codeCatogories = [];
        let content = '';
        this.package_value = [];
        this._set_package_content('');
        console.log(this.classTypeList);
        this.classTypeList.forEach((item,index) => {
            item.classlist.forEach((item_1,index_1) => {
                if (item_1.checked) {
                    if (item_1.Children && item_1.Children.length > 0) {
                        let _title = item_1.course_title+' '+item_1.all_count+'/'+item_1.select_count;
                        content+=_title+',';
                        this.package_value.push(_title);
                        item_1.Children.forEach((item_2,index_2) => {
                            if (item_2.checked) {
                                let tmp = {
                                    course_id: item_1.course_id,
                                    course_title: item_1.course_title,
                                    category_id: item_2.category_id,
                                    category_title: item_2.category_title,
                                    lesson_list: [],
                                    all_count: item_2.all_count || 0,
                                    course_type: item.key
                                };
                                tmp.category_id = item_2.category_id;
                                tmp.category_title = item_2.category_title;
                                tmp.lesson_list = item_2.lesson_list;
                                codeCatogories.push(tmp);
                            }
                        });
                    } else {
                        let tmp = {
                            course_id: item_1.course_id,
                            course_title: item_1.course_title,
                            category_id: item_1.category_id,
                            category_title: item_1.category_title,
                            lesson_list: [],
                            all_count: item_1.all_count || 0,
                            course_type: item.key
                        };
                        this.package_value.push(tmp.category_title);
                        content+=tmp.category_title+',';
                        codeCatogories.push(tmp);
                    }
                }
            });
        });

        if (codeCatogories.length == 0) {this.package_value = [];}
        // codeCatogories.forEach((item,index) => {
        //     let title;
        //     if (item.course_id) {
        //         title = item.course_title;
        //         title += ' ' + item.lesson_list.length + '/' + item.all_count;
        //     } else {
        //         title = item.category_title;
        //     }
        //     content+=title + ',';
        //     this.package_value.push(title);
        // });
        this._set_package_content(content);
        this.codeCatogories = codeCatogories;
     },
     modelCancel(e){
        // this.codeCatogories = [];
        // this.classTypeList = [];
        this.lessonState = false;
     },
     onChangeChannel(value){
        this.sendChannel = value;
     },
     _init_codeCatogories(){
        this.codeCatogories = [];
     },
     openClass(){

        if (this.classTypeList.length > 0) {
            this.lessonState = true;
            return;
        }

        this.$message.loading('数据加载中...');

        this.$axios.post(8000153, {},res => {
            if (res.data.code == 1) {
                this.lessonState = true;
                this.classTypeList = res.data.data;
                this.classList = this.classTypeList[0].classlist;
                this.classTypeValue = this.classTypeList[0].key;
            }
            this.$message.destroy();
        });
     },
     onLessonCheck(value){
        console.log(value);
     },
     openClassCate(item,i,j){
        this._tmp_index_1 = i;
        this._tmp_index_2 = j;
        this.categoryList = item.Children || [];
        this.course_unique =item.course_unique
        this.courseLessonList=null  //重置
     },
     openCategoryCate(item,i,j){
         this._tmp_index_3 = j;
         this.category_unique= item.category_unique
         this.courseLessonList = item.lesson_list || null;
         this.checkAllLessonByCate=true
         for (const Key in this.courseLessonList) {
            if( this.courseLessonList[Key].checked===false||this.courseLessonList[Key].checked===null ){
                this.checkAllLessonByCate =false
                break
            }
         }
         this.showCourseLessonList=true
     },
     onLessonSelect(item,i,event){
        let state = event.target.checked;
        this.classList[i].checked = state;
        if (this.classTypeValue == 2) {
            if (state) {
                this.classTypeList[this.classTypeValue-1].select_count++;
            } else {
                this.classTypeList[this.classTypeValue-1].select_count--;
            }
        } else {
            if (state) {
                this.classList[i].select_count+= item.all_count;
                this.classTypeList[this.classTypeValue-1].select_count+=item.all_count;
            } else {
                this.classList[i].select_count-= item.all_count;
                if (this.classList[i].select_count < 0) {
                    this.classList[i].select_count = 0
                }
                this.classTypeList[this.classTypeValue-1].select_count-=item.all_count;
            }
            this._set_category_select_count(state,i);
        }
     },
     onLessonSelect_1(item,i,event){
        let state = event.target.checked;
        this.categoryList[i].checked = state;
        console.log(item);
        if (state) {
            this.classList[this._tmp_index_2].checked = true;
            this.classList[this._tmp_index_2].select_count += item.all_count;
            this.categoryList[i].select_count = item.all_count;
        } else {
            this.categoryList[i].select_count = 0;
            this.classList[this._tmp_index_2].select_count -= item.all_count;
            if (this.classList[this._tmp_index_2].select_count == 0) {
                this.classList[this._tmp_index_2].checked = false;
            }
        }
        this._set_classtype_select_count(state,item.all_count);
     },

        onLessonOneCheck(item){
         console.log(item)
          item.checked =!item.checked
        },
        // 全选、反选当前分类下的课时
        onLessonSelectAll(){
            if (this.checkAllLessonByCate===true){  //已经全选
                this.courseLessonList.forEach((item,index)=>{
                    item.checked=false
                })
            }else {
                this.courseLessonList.forEach((item,index)=>{
                    item.checked=true
                })
            }
            this.checkAllLessonByCate=!this.checkAllLessonByCate
        } ,
     onLessonCheckAll(event){
        this.lessonCheckAllState = event.target.checked;

     },
     switchClass(item){
        this.package_type = item.key;
        this.categoryList = [];
        this.classList = item.classlist;
        this.classTypeValue = item.key;
        this.courseLessonList=[];
        this.showCourseLessonList=false
     },
     _set_classtype_select_count(state,count){
        if (state) {
            this.classTypeList[this.classTypeValue-1].select_count += count;
        } else {
            this.classTypeList[this.classTypeValue-1].select_count -= count;
            if (this.classTypeList[this.classTypeValue-1].select_count < 0) {
                this.classTypeList[this.classTypeValue-1].select_count = 0;
            }
        }
     },
     _set_category_select_count(state,i){
        this.classList[i].Children.forEach((item,index) => {
            if (state) {
                item.select_count = item.all_count;
                item.checked = true;
            } else {
                item.select_count = 0;
                item.checked = false;
            }
        });
     },
     _showEdit(item){
        this.is_edit = true;
        this.phone = item.phone;
        this.onTtableClick();
        this.searchUser();
     },
     _package_del(item){
        console.log(item);
        let that = this;
        this.$confirm({
            title: '温馨提示',
            content: '确认要删除套餐包吗？',
            onOk() {
                that.$axios.post(8000158, {package_id:item.package_id},res => {
                if(res.data.code==1){
                    that._getData();
                }else{
                    that.$message.error('操作失败');
                }
            });
            },
            onCancel() {
                console.log('no');
            },
        });
     },
     _clearPackageValue(value){
        console.log(value);
        let that = this;

        if (value.length == 0) {
            that.classTypeList = [];
            that.classList = [];
            that.package_value = [];
            that.categoryList = [];
            that._create_package_data();
            return;
        }

        var result = value.concat(that.package_value).filter(function (v) {
            return value.indexOf(v)===-1 || that.package_value .indexOf(v)===-1
        })

        that.classTypeList.forEach((item,index) => {
            item.classlist.forEach((item_1,index_1) => {
                
                let title;

                if (item_1.Children) {
                    title = item_1.course_title;
                    title += ' ' + item_1.select_count + '/' + item_1.all_count;
                    if (result[0] === title) {
                        item_1.checked = false;
                        item.select_count-=item_1.select_count;
                        if (item.select_count < 0) {
                            item.select_count = 0;
                        }
                        item_1.Children.forEach((item_2,index_2) =>{
                            item_2.checked = false;
                        });
                    }
                } else {
                    title = item_1.category_title;
                    if (result[0] === title) {
                        item_1.checked = false;
                        item.select_count--;
                        if (item.select_count < 0) {
                            item.select_count = 0;
                        }
                    }
                }
            });
        });
        that._create_package_data();
     },
     _getSendChannel(){
        if (this.channelList.length > 0) {return;}
        this.$axios.post(8000134, {name: 'sendChannel'},res => {
            if(res.data.code==1){
                this.channelList = res.data.data;
            }else{
                this.channelList = [];
            }
        });
     },
     searchUser(){
        if (!this.phone) {
            this.$message.error('手机号不能为空！');
            return;
        }
        this.$message.loading('查询数据中...');
        this.$axios.get(8000211, {phone: this.phone},res => {
            this.isSearch = true;
            if (res.data.code == 1) {
                this.classTypeList = res.data.course.list;
                this.user_unique = res.data.user_unique;
                this.isReg = true;
                if (this.classTypeList) {
                    this.classList = this.classTypeList[0].classlist;
                    this.classTypeValue = this.classTypeList[0].key;
                    this._create_package_data();
                }
            } else {
                this.isReg = false;
            }
            this.$message.destroy();
        });
     },


     registerUser(){
        if (!this.phone) {
            this.$message.error('手机号不能为空！');
            return;
        }
        this.$axios.post(8000212, {phone: this.phone},res => {
            if (res.data.code == 1) {
                this.user_unique = res.data.user_unique;
                this.isReg = true;
            } else {
                this.isReg = false;
            }
        });
     },
    },
}
</script>

<style lang="less" > 
    .Coupon{
         .item-header{
            padding: 15px 20px;
            background: #fff;
            border-radius: 4px;
            .screen-list-item{
                margin-right: 20px;
                display: inline-block;
            }
            .screen-item-label{
                margin-right: 10px;
                color: #666;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .coupon-content{
            padding: 15px;
            background: #fff;
            margin-top: 20px;
            border-radius: 4px;
            .content-title{
                span{
                    font-size: 18px;
                    color: #333;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .smalldash{
                line-height: 30px;
            }
            .edit-item{
                font-size:12px;
            }
        }
    }
    .Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
    .Coupon .ant-select-selection__rendered{line-height: 34px;}
    .Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 6px;
    }
    .coupon_form{padding-bottom: 30px;}
    .coupon_form .ant-row .ant-form-item-label{text-align: left;}
    .coupon_form .ant-radio-group .ant-input-number{width: 124px;}
    .coupon_form .ant-input-number{width:330px;}

    .item-list{
        display: flex;
        overflow: hidden;
        margin-bottom: 25px;
        .item-content{
            float: right;
            width: 400px;
        }
        label{
            margin-top: 6px;
            margin-right: 15px;
        }
    }
    .line{
        border: solid 1px;
        border-color: #f5f5f5;
        width: 1px;
        height: 100%;
        float: left;
    }
    .lesson-list{
        display: inline-block;
        float: left;
        flex: 1;
        width: 20%;
    }
    .class-item{
        margin: 10px;
    }
    .class-title{
        margin-left: 10px;
    }
    .lessonCheckAll{
        margin-left: 10px;
    }
    .register{
        margin-top: 5px;
        color: #52c41a;
    }
    .unregister{
        margin-top: 1px;
        .unregister-text{
            color: red;
        }
        button{
            margin-top: 4px;
            margin-left: 5px;
            font-size: 10px;
            height: 25px;
        }
    }
</style>